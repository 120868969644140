import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  //  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { Modal, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';
//  import moment from 'moment';
//  import { Switch } from '@mui/material';
import { Switch, notification } from 'antd';
import { NotificationManager } from 'react-notifications';
//  import { DataGrid } from '@mui/x-data-grid';
//  import moment from 'moment';
import Layout from '../../../shared/Layout';
import '../index';
//  import AntDropdown from '../../../shared/Dropdown/antDropdown';
import {
  customBaseUrl,
  httpGet,
  httpPatch,
  //  httpPatch,
  httpPost,
  httpPut,
} from '../../../../action/http';
//  import AntDropdown from '../../../shared/Dropdown/antDropdown';
import ContestantDetails from './contestantDetails';
import { hideLoader, showLoader } from '../../../../utils/loader';
import { WayagramContext } from '../../../../store/context/WayagramContext';
//  import { fileValidation } from '../../../../utils/validateImage';

const ContestSettings = (props) => {
  //  const history = useHistory();
  const selectedC = [];
  const { profileId, contestId } = useParams();
  const { wayagramProfileId } = useContext(WayagramContext);
  //    const [showSharePost, setShowSharePost] = useState(false);
  const [contests, setContests] = useState([]);
  const [showGeneric, setShowGeneric] = useState(false);
  const [reason, setReason] = useState('');
  const [selectedData, setSelectedData] = useState({});
  const [searchText, setSearchText] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [genericData, setGenericData] = useState([]);
  const [unis, setUnis] = useState([]);
  const [sContest, setSContest] = useState([]);
  const [selectedContests, setSelectedContest] = useState([]);
  // const [preview, setPreview] = useState('');
  // const [data, setData] = useState([]);

  const handleGetContestDetails = async () => {
    // const search = `all-posts/search-products?query=${value}`;
    const uri = `contests?profileId=${wayagramProfileId}&page=1&limit=1000`;
    const res = await httpGet(`${customBaseUrl.contestUrl}/${uri}`);
    if (res?.status) {
      const contest = res?.data;
      const rank = contest?.sort((a, b) => {
        const fa = a?.rank?.position;
        const fb = b?.rank?.position;

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      setContests(rank);
      //  setLoading(false);
    }
  };
  const handleUpdateSettings = async (e, stage, id) => {
    console.log({ e, id });
    const postData = {
      contestId: id,
      stageNumber: stage,
      isEnabled: e,
    };
    showLoader();
    const url = '/contests/toggle-contest-stage';
    const res = await httpPatch(url, postData, customBaseUrl.contestUrl);
    const { status, message } = res || {};
    if (status) {
      hideLoader();
      handleGetContestDetails();
      notification.success({ description: message, message: 'success!' });
    } else {
      hideLoader();
      notification.error({ description: message, message: 'Oops!' });
    }
  };
  const getUnis = async () => {
    const res = await httpGet(
      `${customBaseUrl.contestUrl}/contests/universities`
    );
    if (res?.universities) {
      setUnis(res?.universities);
    } else {
      console.log(res);
    }
  };
  const handleAcceptReject = async (action, d, r) => {
    showLoader();
    const dat = {
      action,
      profileId,
      contestantIds: [d?.id],
      reason: r,
    };
    console.log({ dat });
    const res = await httpPost(
      `/contests/decide-contestants`,
      dat,
      customBaseUrl.contestUrl
    );
    if (res?.status) {
      hideLoader();
      swal('Successful', res?.message);
      console.log({ res });
      setReason('');
      setShowGeneric(false);
      setShowMore(false);
      handleGetContestDetails();
    } else {
      hideLoader();
      swal('Oops!', res?.message);
      console.log('erroe', res);
      setReason('');
    }
  };
  const handleGenericUpdate = async () => {
    const pData = {
      contestIds: selectedContests,
      fieldsToUpdate: {
        type: genericData.type,
        stageOneEnabled: genericData.stageOneEnabled,
        stageTwoEnabled: genericData.stageTwoEnabled,
        stageThreeEnabled: genericData.stageThreeEnabled,
        amountPerVote: genericData.type === 'PAID' ? genericData.amount : 0,
      },
    };
    showLoader();
    const res = await httpPut(
      '/contests/bulk-edit',
      pData,
      customBaseUrl.contestUrl
    );
    if (res.status) {
      hideLoader();
      setShowGeneric(false);
      swal(res.message);
      handleGetContestDetails();
      setGenericData({});
    } else {
      hideLoader();
      swal(res.message);
    }
  };

  useEffect(() => {
    console.log({ profileId, contestId });
    getUnis();
    handleGetContestDetails();
  }, []);
  useEffect(() => {
    const sContests = [];
    contests.map((e) => {
      if (e.contest.checked) {
        sContests.push(e.contest.id);
      }
      return e;
    });
    setSelectedContest(sContests);
  }, [contests]);
  const handleUpdate = async (i) => {
    const newForm = new FormData();
    newForm.append('id', contests[i]?.contest?.id);
    newForm.append('profileId', contests[i]?.contest?.profileId);
    newForm.append('title', contests[i]?.contest?.title);
    //  newForm.append('category', values?.category);
    newForm.append('state', contests[i]?.contest?.state);
    newForm.append('winnerPrize', contests[i]?.contest?.winnerPrize);
    newForm.append('maxContestants', contests[i]?.contest?.maxContestants);
    newForm.append('description', contests[i]?.contest?.description);
    newForm.append('numberOfWeeks', contests[i]?.contest?.numberOfWeeks);
    newForm.append('voteBenchmark', contests[i]?.contest?.voteBenchmark);
    newForm.append(
      'stageOneVoteCount',
      contests[i]?.contest?.stageOneVoteCount
    );
    newForm.append(
      'stageTwoVoteCount',
      contests[i]?.contest?.stageTwoVoteCount
    );
    newForm.append(
      'stageThreeVoteCount',
      contests[i]?.contest?.stageThreeVoteCount
    );
    // newForm.append('amountPerVote', values?.amountPerVote);
    newForm.append('type', contests[i]?.contest?.type);
    newForm.append(
      'amountPerVote',
      contests[i]?.contest?.type === 'PAID'
        ? contests[i]?.contest?.amountPerVote
        : 0
    );
    newForm.append('overallResult', contests[i]?.contest?.overallResult);
    newForm.append('university', contests[i]?.contest?.university);
    newForm.append('status', contests[i]?.contest?.status);
    newForm.append('publicity', contests[i]?.contest?.publicity);
    // newForm.append('startDate', contests[i]?.contest?.startDate);
    // newForm.append('endDate', contests[i]?.contest?.endDate);
    // newForm.append('stageOneEnabled', contests[i]?.contest?.stageOneEnabled);
    // newForm.append('stageTwoEnabled', contests[i]?.contest?.stageTwoEnabled);
    // newForm.append(
    //   'stageThreeEnabled',
    //   contests[i]?.contest?.stageThreeEnabled
    // );
    newForm.append(
      'stageOneStartDate',
      contests[i]?.contest?.stageOneStartDate
    );
    newForm.append('stageOneEndDate', contests[i]?.contest?.stageOneEndDate);
    newForm.append(
      'stageTwoStartDate',
      contests[i]?.contest?.stageTwoStartDate
    );
    newForm.append('stageTwoEndDate', contests[i]?.contest?.stageTwoEndDate);
    newForm.append(
      'stageThreeStartDate',
      contests[i]?.contest?.stageThreeStartDate
    );
    newForm.append(
      'stageThreeEndDate',
      contests[i]?.contest?.stageThreeEndDate
    );
    newForm.append(
      'registrationStartDate',
      contests[i]?.contest?.registrationStartDate
    );
    newForm.append(
      'registrationEndDate',
      contests[i]?.contest?.registrationEndDate
    );
    // if (coverPhoto) newForm.append('coverPhoto', coverPhoto);
    // if (coverVideo) newForm.append('coverVideo', coverVideo);
    //  if (!values.amountPerVote) delete updateData?.amountPerVote;
    console.log({ newForm });
    showLoader();
    const res = await httpPatch(
      `/contests/update`,
      newForm,
      customBaseUrl.contestUrl
    );
    if (res.status) {
      NotificationManager.success(res?.message, 'Done!', 3000);
      hideLoader();
      //  setLoading(false);
      //  hideModal(false);
      handleGetContestDetails();
    } else {
      hideLoader();
      //  setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };
  return (
    <Layout route={props} currentPage="contest" pageName="" showName>
      {!showMore ? (
        <>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="mt-4 mb-3 mx-auto search-container d-flex justify-content-between">
              <input
                className="w-50 form-control search-input"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              {/* <button
                type="button"
                className="btn btn-primary"
                onClick={() => setShowGeneric(true)}
              >
                Generic Update
              </button> */}
            </div>
            <div className="mt-4 mb-3 mx-auto search-container d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setShowGeneric(true)}
              >
                Generic Update
              </button>
            </div>
          </div>
          <Modal
            isOpen={showGeneric}
            toggle={() => {
              setShowGeneric(false);
              // setPreview([]);
            }}
            centered
          >
            <ModalHeader
              className="text-center"
              toggle={() => setShowGeneric(false)}
            >
              <span>Generic Edit</span>
            </ModalHeader>
            {/* <div style={{ margin: 10, width: '100%', textAlign: 'center' }}>
              <textarea
                onChange={(e) => setReason(e.target.value)}
                className="px-3 border w-75"
                value={reason}
              />
            </div> */}
            <div className="mt-3 col-md-10 mx-2">
              <label className="form-label">Contest type</label>
              <select
                className="form-select border"
                //  style={{ width: 250 }}
                name="type"
                value={genericData.type}
                onChange={(val) => {
                  setGenericData({ ...genericData, type: val.target.value });
                }}
              >
                <option>select type</option>
                <option value="FREE">FREE</option>
                <option value="PAID">PAID</option>
              </select>
            </div>
            <div className="mt-3 col-md-10 mx-2">
              <label className="form-label">Contest Stage</label>
              <div>
                <input
                  type="checkbox"
                  name="stage"
                  className="mx-2"
                  checked={genericData.stageOneEnabled}
                  onChange={(e) =>
                    setGenericData({
                      ...genericData,
                      stageOneEnabled: e.target.checked,
                    })
                  }
                />
                <label className="form-label">Stage One</label>
                <input
                  type="checkbox"
                  name="stage"
                  className="mx-2"
                  checked={genericData.stageTwoEnabled}
                  onChange={(e) =>
                    setGenericData({
                      ...genericData,
                      stageTwoEnabled: e.target.checked,
                    })
                  }
                />
                <label className="form-label">Stage Two</label>
                <input
                  type="checkbox"
                  name="stage"
                  className="mx-2"
                  checked={genericData.stageThreeEnabled}
                  onChange={(e) =>
                    setGenericData({
                      ...genericData,
                      stageThreeEnabled: e.target.checked,
                    })
                  }
                />
                <label className="form-label">Stage Three</label>
              </div>
            </div>
            <div className="mt-3 col-md-10 mx-2">
              <label className="form-label">Number of Votes</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Number of Votes"
                  value={genericData?.noOfVotes}
                  onChange={(val) =>
                    setGenericData({
                      ...genericData,
                      noOfVotes: val.target.value,
                    })
                  }
                />
              </div>
            </div>
            {genericData?.type === 'PAID' ? (
              <div className="mt-3 col-md-10 mx-2">
                <label className="form-label">Contest Amount</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Contest Amount"
                    value={genericData?.amount}
                    onChange={(val) =>
                      setGenericData({
                        ...genericData,
                        amount: val.target.value,
                      })
                    }
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="d-flex justify-content-between mx-3 my-3">
              <button
                className="btn px-2 py-3"
                type="button"
                onClick={() => {
                  setGenericData({});
                  setShowGeneric(false);
                }}
              >
                Reset
              </button>
              {selectedContests.length > 0 ? (
                <button
                  className="btn text-end btn-success px-2 py-3"
                  //  style={{ backgroundColor: 'blue' }}
                  type="button"
                  onClick={handleGenericUpdate}
                >
                  Apply to {selectedContests.length} selected contests
                </button>
              ) : (
                ''
              )}
              <button
                className="btn text-end btn-primary px-2 py-3"
                type="button"
                onClick={handleGenericUpdate}
              >
                Apply to all
              </button>
            </div>
          </Modal>
          {contests.length > 0 ? (
            // <DataGrid
            //   //  className={styles.table}
            //   getRowId={(row) => row?.contest.id}
            //   rows={
            //     searchText
            //       ? contests?.filter(
            //           (e) =>
            //             e?.firstName
            //               ?.toLowerCase()
            //               ?.includes(searchText?.toLowerCase()) ||
            //             e?.lastName
            //               ?.toLowerCase()
            //               ?.includes(searchText?.toLowerCase()) ||
            //             e?.email
            //               ?.toLowerCase()
            //               ?.includes(searchText?.toLowerCase())
            //         )
            //       : contests
            //   }
            //   density="comfortable"
            //   columns={columns}
            //   // page={pagination.current}
            //   // paginationMode="server"
            //   // pageSize={pagination.pageSize}
            //   // onPageSizeChange={(newPageSize) =>
            //   //   handleChangeRowsPerPage(newPageSize)
            //   // }
            //   // onPageChange={(newPage) => handleChangePage(newPage)}
            //   // rowsPerPageOptions={[5, 10, 20, 50, 100]}
            //   // pagination
            //   //  components={{ Toolbar: GridToolbar }}
            //   //  rowCount={metaData}
            // />
            <table className="table">
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Title</th>
                  <th>University</th>
                  <th>Type</th>
                  <th>Amount per Vote</th>
                  {/* <th>Current Stage</th> */}
                  <th>Winner Prize </th>
                  {/* <th>Contest Photo </th>
                  <th>Contest Video </th> */}
                  <th>Registration Start Date</th>
                  <th>Registration End Date</th>
                  <th>StageOne Start Date</th>
                  <th>StageOne End Date </th>
                  <th style={{ width: 250 }}>StageTwo Start Date</th>
                  <th style={{ width: 250 }}>StageTwo End Date </th>
                  <th className="w-auto">StageThree Start Date</th>
                  <th className="w-auto">StageThree End Date </th>
                  <th className="w-auto">StageOne Vote Count</th>
                  <th className="w-auto">StageTwo Vote Count </th>
                  <th style={{ width: 250 }}>StageThree Vote Count</th>
                  <th style={{ width: 250 }}>StageOne Status </th>
                  <th style={{ width: 250 }}>StageTwo Status </th>
                  <th style={{ width: 250 }}>StageThree Status </th>
                </tr>
              </thead>
              <tbody>
                {contests?.map((e, i) => (
                  <tr key={e.contest.id}>
                    <td>
                      <input
                        checked={e.contest.checked}
                        type="checkbox"
                        name="selectedC"
                        aria-label="select"
                        onChange={() => {
                          const newAr = [...contests];
                          const index = sContest.indexOf(e.contest.id);
                          if (e.contest.checked && index > -1) {
                            const newA = sContest.splice(index, 1);
                            setSContest([newA]);
                          } else {
                            setSContest([...sContest, e.contest.id]);
                            selectedC.push(e.contest.id);
                          }
                          const cont = {
                            ...newAr[i].contest,
                            checked: !e.contest.checked,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        value={e.contest.title}
                        name="title"
                        aria-label="title"
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            title: val.target.value,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    <td>
                      {/* <input
                        value={e.contest.university}
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            univeristy: val.target.value,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      /> */}
                      <select
                        className="form-select border"
                        style={{ width: 250 }}
                        name="university"
                        value={e.contest.university}
                        onChange={(val) => {
                          console.log({ val: val.target.value });
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            university: val.target.value,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      >
                        <option>select school</option>
                        {unis
                          ? unis.map((item) => (
                              <option value={item} key={item}>
                                {item?.toUpperCase()}
                              </option>
                            ))
                          : ''}
                      </select>
                    </td>
                    <td>
                      <select
                        className="form-select border"
                        style={{ width: 250 }}
                        name="type"
                        value={e.contest.type}
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            type: val.target.value,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      >
                        <option>select type</option>
                        <option value="FREE">FREE</option>
                        <option value="PAID">PAID</option>
                      </select>
                    </td>
                    <td>
                      <input
                        value={
                          e.contest.type === 'FREE'
                            ? 0
                            : e.contest.amountPerVote
                        }
                        aria-label="type"
                        disabled={e.contest.type === 'FREE'}
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            amountPerVote: val.target.value,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    {/* <td style={{ width: 250 }}>
                      <select
                        className="form-select border"
                        style={{ width: 250 }}
                        name="type"
                        value={e.contest.currentStage}
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            currentStage: val.target.value,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      >
                        <option>select type</option>
                        <option value="stage-one">Stage 1</option>
                        <option value="stage-two">Stage 2</option>
                        <option value="stage-three">Stage 3</option>
                      </select>
                    </td> */}
                    <td style={{ width: 250 }}>
                      <input
                        name="winnerPrize"
                        aria-label="winnerPrize"
                        value={e.contest.winnerPrize}
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            winnerPrize: val.target.value,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    {/* <td>
                      <label
                        style={{
                          backgroundImage: e.contest.preview
                            ? `url(${e.contest.preview})`
                            : `url(${e.contest.miscellaneous.coverPhoto})`,
                          backgroundSize: 'cover',
                          height: '140px',
                          width: '100%',
                          backgroundPosition: 'left',
                          //  border: 1,
                          borderStyle: 'dotted',
                          borderTopLeftRadius: 14,
                          borderTopRightRadius: 14,
                        }}
                        htmlFor="contestPhoto"
                      >
                        Click here to upload contest photo*
                      </label>
                      <input
                        hidden
                        //  ref={hiddenFileInput}
                        id="contestPhoto"
                        name="contestPhoto"
                        placeholder="Click here to upload"
                        type="file"
                        accept="image/*"
                        onChange={(v) => {
                          const validFormat = fileValidation(v.target.files[0]);
                          // if (e.target.files[0].size > 100000) {
                          //   setPreview('');
                          //   return swal(
                          //     'Oops!',
                          //     'Please contest Photo should be less than 100kb',
                          //     'error'
                          //   );
                          // }
                          if (!validFormat) {
                            return swal(
                              'Oops!',
                              'Please upload file image only.',
                              'error'
                            );
                          }
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            contestPhoto: v.target.files[0],
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                          const reader = new FileReader();
                          const url = reader.readAsDataURL(v.target.files[0]);
                          reader.onloadend = () => {
                            //  const newAr = [...contests];
                            const conts = {
                              ...newAr[i].contest,
                              preview: reader.result,
                            };
                            newAr[i] = { ...newAr[i], contest: conts };
                            setContests(newAr);
                            //  return true;
                          };
                          return url;
                        }}
                      />
                    </td>
                    <td>
                      <input
                        value={e.contest.amountPerVote}
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            amountPerVote: val.target.value,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td> */}
                    <td className="w-auto">
                      <DatePicker
                        style={{ width: 200 }}
                        className="w-100 border py-1 rounded"
                        aria-label="registrationStartDate"
                        // showTimeSelect
                        selected={
                          new Date(e.contest.registrationStartDate) ??
                          new Date()
                        }
                        //  minDate={new Date(postData?.stageThreeEndDate)}
                        timeIntervals={15}
                        // onChange={(date) => {
                        //   setPostData({
                        //     ...postData,
                        //     stageThreeEndDate: date.getTime(),
                        //     // endTime: date.getTime(),
                        //   });
                        // }}
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            registrationStartDate: val.getTime(),
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                      {/* <input
                        type="date"
                        value={new Date(e.contest.registrationStartDate)}
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            registrationStartDate: val.target.value,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      /> */}
                    </td>
                    <td className="w-auto">
                      <DatePicker
                        aria-label="registrationEndDate"
                        className="w-100 border py-1 rounded"
                        style={{ width: 200 }}
                        // showTimeSelect
                        selected={
                          new Date(e.contest.registrationEndDate) ?? new Date()
                        }
                        //  minDate={new Date(postData?.stageThreeEndDate)}
                        timeIntervals={15}
                        // onChange={(date) => {
                        //   setPostData({
                        //     ...postData,
                        //     stageThreeEndDate: date.getTime(),
                        //     // endTime: date.getTime(),
                        //   });
                        // }}
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            registrationEndDate: val.getTime(),
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    <td className="w-auto">
                      <DatePicker
                        className="w-100 border py-1 rounded"
                        aria-label="stage OneStartDate"
                        style={{ width: 200 }}
                        // showTimeSelect
                        selected={
                          new Date(e.contest.stageOneStartDate) ?? new Date()
                        }
                        //  minDate={new Date(postData?.stageThreeEndDate)}
                        timeIntervals={15}
                        // onChange={(date) => {
                        //   setPostData({
                        //     ...postData,
                        //     stageThreeEndDate: date.getTime(),
                        //     // endTime: date.getTime(),
                        //   });
                        // }}
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            stageOneStartDate: val.getTime(),
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    <td className="w-auto">
                      <DatePicker
                        className="w-100 border py-1 rounded"
                        aria-label="stageOneEStartDate"
                        style={{ width: 200 }}
                        // showTimeSelect
                        selected={
                          new Date(e.contest.stageOneEndDate) ?? new Date()
                        }
                        //  minDate={new Date(postData?.stageThreeEndDate)}
                        timeIntervals={15}
                        // onChange={(date) => {
                        //   setPostData({
                        //     ...postData,
                        //     stageThreeEndDate: date.getTime(),
                        //     // endTime: date.getTime(),
                        //   });
                        // }}
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            stageOneEndDate: val.getTime(),
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    <td className="w-auto">
                      <DatePicker
                        className="w-100 border py-1 rounded"
                        aria-label="stageTwoStartDate"
                        style={{ width: 200 }}
                        // showTimeSelect
                        selected={
                          new Date(e.contest.stageTwoStartDate) ?? new Date()
                        }
                        //  minDate={new Date(postData?.stageThreeEndDate)}
                        timeIntervals={15}
                        // onChange={(date) => {
                        //   setPostData({
                        //     ...postData,
                        //     stageThreeEndDate: date.getTime(),
                        //     // endTime: date.getTime(),
                        //   });
                        // }}
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            stageTwoStartDate: val.getTime(),
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    <td className="w-auto">
                      <DatePicker
                        className="w-100 border py-1 rounded"
                        aria-label="stageTwoEndDate"
                        style={{ width: 200 }}
                        // showTimeSelect
                        selected={
                          new Date(e.contest.stageTwoEndDate) ?? new Date()
                        }
                        //  minDate={new Date(postData?.stageThreeEndDate)}
                        timeIntervals={15}
                        // onChange={(date) => {
                        //   setPostData({
                        //     ...postData,
                        //     stageThreeEndDate: date.getTime(),
                        //     // endTime: date.getTime(),
                        //   });
                        // }}
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            stageTwoEndDate: val.getTime(),
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    <td style={{ width: 250 }}>
                      <DatePicker
                        className="w-100 border py-1 rounded"
                        aria-label="stageThreeStartDate"
                        style={{ width: 200 }}
                        // showTimeSelect
                        selected={
                          new Date(e.contest.stageThreeStartDate) ?? new Date()
                        }
                        //  minDate={new Date(postData?.stageThreeEndDate)}
                        timeIntervals={15}
                        // onChange={(date) => {
                        //   setPostData({
                        //     ...postData,
                        //     stageThreeEndDate: date.getTime(),
                        //     // endTime: date.getTime(),
                        //   });
                        // }}
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            stageThreeStartDate: val.getTime(),
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    <td style={{ width: 250 }}>
                      <DatePicker
                        className="w-100 border py-1 rounded"
                        style={{ width: 200 }}
                        aria-label="stageThreeEndDate"
                        // showTimeSelect
                        selected={
                          new Date(e.contest.stageThreeEndDate) ?? new Date()
                        }
                        //  minDate={new Date(postData?.stageThreeEndDate)}
                        timeIntervals={15}
                        // onChange={(date) => {
                        //   setPostData({
                        //     ...postData,
                        //     stageThreeEndDate: date.getTime(),
                        //     // endTime: date.getTime(),
                        //   });
                        // }}
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            stageThreeEndDate: val.getTime(),
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        value={e.contest.stageOneVoteCount}
                        aria-label="stageOneVoteCount"
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            stageOneVoteCount: val.target.value,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        value={e.contest.stageTwoVoteCount}
                        aria-label="stageTwoVoteCount"
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            stageTwoVoteCount: val.target.value,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        value={e.contest.stageThreeVoteCount}
                        aria-label="stageThreeVoteCount"
                        onChange={(val) => {
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            stageThreeVoteCount: val.target.value,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    <td>
                      <Switch
                        checked={e.contest.stageOneEnabled}
                        aria-label="stageOneEnable"
                        onChange={(k) => {
                          handleUpdateSettings(k, 1, e.contest.id);
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            stageOneEnabled: !e.contest.stageOneEnabled,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    <td>
                      <Switch
                        checked={e.contest.stageTwoEnabled}
                        aria-label="stageTwoEnable"
                        onChange={(k) => {
                          handleUpdateSettings(k, 2, e.contest.id);
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            stageTwoEnabled: !e.contest.stageTwoEnabled,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    <td>
                      <Switch
                        checked={e.contest.stageThreeEnabled}
                        aria-label="stageThreeEnable"
                        onChange={(k) => {
                          handleUpdateSettings(k, 3, e.contest.id);
                          const newAr = [...contests];
                          const cont = {
                            ...newAr[i].contest,
                            stageThreeEnabled: !e.contest.stageThreeEnabled,
                          };
                          newAr[i] = { ...newAr[i], contest: cont };
                          setContests(newAr);
                        }}
                      />
                    </td>
                    <td>
                      <button type="button" onClick={() => handleUpdate(i)}>
                        Update
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No Record Found</div>
          )}
        </>
      ) : (
        <ContestantDetails
          setShowMore={setShowMore}
          data={selectedData}
          setSelectedData={setSelectedData}
          setShowGeneric={setShowGeneric}
          reason={reason}
          setReason={setReason}
          handleGenericUpdate={handleGenericUpdate}
          handleAcceptReject={handleAcceptReject}
          isAdmin
        />
      )}
    </Layout>
  );
};

export default ContestSettings;
