import React, { createContext, useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { customBaseUrl, httpGet, httpPost, httpPut } from '../../action/http';
import {
  getPageSuggestion,
  getGroupSuggestion,
  getFriendSuggestion,
  getNotifications,
  getRequestNotifications,
} from '../../components/pages/Wayagram/container';
import {
  darkModalStyle,
  darkStyle,
  lightModalStyle,
  lightStyle,
  userTheme,
} from '../initialState';

export const WayagramContext = createContext();

export const WayagramContextProvider = (props) => {
  const { children } = props;
  const setTheme = useSetRecoilState(userTheme);
  const pid = localStorage.getItem('wayaProfileId');
  const [wayagramProfile, setWayagramProfile] = useState({});
  const [wayagramProfileId, setWayagramProfileId] = useState(pid || null);
  const [states, setStates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [wayagramFeeds, setWayagramFeeds] = useState([]);
  const [notifications, setNotifications] = useState({});
  const [reqNotifications, setReqNotifications] = useState([]);
  const [notificationTab, setNotificationTab] = useState('all');
  const [reqNotificationTab, setReqNotificationTab] = useState('event');
  const [wayagramReload, setWayagramReload] = useState(false);
  const [reload, setReload] = useState(false);
  const [reloadMoment, setReloadMoment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageSuggestion, setPageSuggestion] = useState([]);
  const [groupSuggestion, setGroupSuggestion] = useState([]);
  const [peopleSuggestion, setPeopleSuggestion] = useState([]);
  const userId = localStorage.getItem('userId');
  const wayaData = localStorage.getItem('wayaUserData');
  const data = wayaData !== 'undefined' && JSON?.parse(wayaData)?.user;
  const getAllNotification = async () => {
    const response = await getNotifications(wayagramProfileId, 1);
    setNotifications(response);
  };

  const getReqNotification = async (id) => {
    setReqNotifications([]);
    const r = await getRequestNotifications(reqNotificationTab, userId, id, 1);
    setReqNotifications(r);
  };

  const createDeviceToken = async () => {
    const device = localStorage.getItem('deviceToken');
    await httpPost(
      `/notification/device-token-create?profile_id=${wayagramProfileId}&user_id=${userId}`,
      {
        token: device,
        platform: 'web',
        mac_address: '35:9B:0E:09:8A',
        device_unique: wayagramProfileId,
      },
      customBaseUrl.wayagramUrl
    );
    setLoading(false);
  };

  const updateEmailAndPhone = async () => {
    const newForm = new FormData();
    newForm.append('email', data.email);
    newForm.append('phone', data.phoneNumber);
    newForm.append('user_id', userId);
    await httpPut(`/main/profile/update`, newForm, customBaseUrl.wayagramUrl);
  };

  const handleGroupSuggestion = async () => {
    const resData = await getGroupSuggestion(wayagramProfileId);
    setGroupSuggestion(resData);
  };

  const handlePageSuggestion = async () => {
    const resData = await getPageSuggestion(wayagramProfileId);
    setPageSuggestion(resData);
  };

  const handlePeopleSuggestion = async () => {
    const resData = await getFriendSuggestion(userId);
    setPeopleSuggestion(resData);
  };

  const getWayagramProfile = async () => {
    setLoading(true);
    const res = await httpGet(
      `${customBaseUrl.wayagramUrl}/main/profile/get-by-user-id?user_id=${userId}`,
      false
    );
    if (res?.status) {
      const t = res?.data?.theme;
      setTheme({
        style: t === 'light' ? lightStyle : darkStyle,
        modalStyle: t === 'light' ? lightModalStyle : darkModalStyle,
        mode: t,
      });
      localStorage.setItem('theme', t);
      localStorage.setItem('wayaProfileId', res?.data?.id);
      setWayagramProfile(res?.data);
      setWayagramProfileId(res?.data?.id);
      setLoading(false);
      // getWayagramFeeds(res.data.id);
    }
  };

  const getAllStates = async () => {
    setLoading(true);
    try {
      const res = await httpGet(
        `https://countriesnow.space/api/v0.1/countries/states`
      );

      if (!res?.error) {
        setStates(res?.data[160]?.states);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCategories = async () => {
    const res = await httpGet(
      `${customBaseUrl.wayagramUrl}/graph/admin-interest/interests?page=1&limit=100`
    );
    if (res?.status) {
      setCategories(res?.data?.data);
    }
  };

  useEffect(() => {
    if (notificationTab === 'all') getAllNotification();
    else getReqNotification(wayagramProfileId);
  }, [reload, notificationTab, wayagramProfileId, reqNotificationTab]);

  useEffect(() => {
    if (wayagramProfileId && !wayagramProfile?.email) {
      updateEmailAndPhone();
    }
    getWayagramProfile();
  }, [wayagramReload]);

  useEffect(() => {
    getAllStates();
  }, []);

  useEffect(() => {
    setLoading(true);
    if (wayagramProfileId) {
      handleGroupSuggestion();
      handlePageSuggestion();
      handlePeopleSuggestion();
      createDeviceToken();
      getCategories();
    }
  }, [wayagramProfileId, reload]);

  return (
    <WayagramContext.Provider
      value={{
        setWayagramProfile,
        wayagramProfile,
        wayagramReload,
        setWayagramReload,
        wayagramProfileId,
        setWayagramProfileId,
        wayagramFeeds,
        setWayagramFeeds,
        groupSuggestion,
        pageSuggestion,
        setReload,
        reload,
        setReloadMoment,
        reloadMoment,
        loading,
        setLoading,
        peopleSuggestion,
        setPeopleSuggestion,
        notifications,
        reqNotifications,
        setNotificationTab,
        notificationTab,
        reqNotificationTab,
        setReqNotificationTab,
        states,
        setStates,
        categories,
        setCategories,
      }}
    >
      {children}
    </WayagramContext.Provider>
  );
};
