import React from 'react';
import { Drawer, List, Avatar } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import imagePlaceholder from '../../../assets/image/default-dp.svg';
// import { NotificationWrapper } from './style';

const NotificationDrawer = ({ onClose, visible, data, theme }) => {
  const themeColor = theme.mode === 'light' ? 'dark' : 'white';
  // console.log(data);
  return (
    <Drawer
      title={<h6 className={`fw-bold text-${themeColor}`}>Notifications</h6>}
      placement="right"
      onClose={() => onClose(false)}
      visible={visible}
      className="fs-5"
    >
      <div className="px-4" style={theme.style}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar src={item?.Profile?.avatar || imagePlaceholder} />
                }
                title={
                  <a
                    className={`text-decoration-none text-${themeColor}`}
                    href={
                      item.type === 'Friend'
                        ? `/wayagram-profile/${item?.PatronId}`
                        : `/notifications`
                    }
                  >
                    {item.content}
                  </a>
                }
                description={
                  <p className={`text-${themeColor}`} style={{ fontSize: 12 }}>
                    {moment(item?.createdAt).format('MMM Do YYYY, h:mm a')}
                  </p>
                }
              />
            </List.Item>
          )}
        />
        {data?.length > 0 && (
          <Link
            to="/notifications"
            className={`btn btn-link text-${themeColor}`}
          >
            View all notifications
          </Link>
        )}
      </div>
    </Drawer>
  );
};

export default NotificationDrawer;
