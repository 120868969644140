import { Avatar, List } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader } from 'reactstrap';
import avatar2 from '../../../../assets/image/default-dp.svg';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import { getFriends } from '../../../pages/Wayagram/container';
import Button from '../../Button';
import Loader from '../../Loader';
import './style.scss';
import { customBaseUrl, httpGet } from '../../../../action/http';

const Invite = (props) => {
  const {
    showModal,
    hideModal,
    btnText,
    title,
    handleNewChat,
    selected,
    setSelected,
    btnLoading,
    //  peopleSuggestions,
    wayagramProfile,
  } = props;

  const {
    profile: { userId },
  } = useContext(ProfileContext);
  const [allContacts, setAllContacts] = useState([]); // [{}
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValues, setSearchValues] = useState('');
  const [searchProfileUsers, setSearchProfileUsers] = useState(false);

  const getAllFriends = async () => {
    setLoading(true);
    const friends = await getFriends(userId);
    setMembers(friends?.friends);
    setAllContacts(friends?.friends);
    setLoading(false);
  };

  useEffect(() => {
    const searchChatFriends = allContacts.filter((item) => {
      if (searchValues.length === 0) return allContacts;

      return (
        item?.displayName
          ?.toLowerCase()
          .includes(searchValues?.toLowerCase()) ||
        item?.username?.toLowerCase().includes(searchValues?.toLowerCase()) ||
        item?.email?.toLowerCase().includes(searchValues?.toLowerCase())
      );
    });
    setMembers(searchChatFriends);
  }, [searchValues]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchValues(value);
    // if (value === '') {
    //   getAllFriends();
    // } else {
    //   const resData = await searchFriends(value);
    //   setMembers(resData);
    // }
  };

  const searchProfile = async (val) => {
    setLoading(true);
    const search = await httpGet(
      `${customBaseUrl.wayagramUrl}/main/profile/search?query=${val}&profile_id=${wayagramProfile.id}`
    );

    if (search.status) {
      setMembers(search?.data);
      // setAllContacts([...search?.data]);
      setSearchProfileUsers(false);
      setLoading(false);
    } else {
      setMembers([]);
      setSearchProfileUsers(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchProfileUsers === true && searchValues.length > 2) {
      searchProfile(searchValues);
    }
  }, [searchProfileUsers]);

  useEffect(() => {
    getAllFriends();
    return () => {
      setMembers([]);
    };
  }, []);

  return (
    <Modal
      isOpen={showModal}
      centered
      toggle={() => hideModal(false)}
      size="md"
      className="invite-modal"
    >
      <ModalHeader>{title || 'Invite Friends'}</ModalHeader>
      {loading ? (
        <div className="mx-auto my-lg-5">
          <Loader />
        </div>
      ) : (
        <div className="request-cont">
          <div className="search-container mb-3">
            <input
              className="w-100 form-control ps-3 search-input"
              placeholder="Search"
              onChange={handleSearch}
              onKeyDown={() => setSearchProfileUsers(false)}
              onKeyUp={() => {
                setTimeout(() => {
                  setSearchProfileUsers(true);
                }, 1000);
              }}
              value={searchValues}
            />
          </div>
          {members?.length /*  (
            members.map((member) => (
              <div className="request" key={member?.id}>
                <div
                  className="req-img"
                  onClick={() => {
                    history.push(`/wayagram-profile/${member?.id}`);
                  }}
                >
                  
                  <img src={member?.avatar || avatar2} alt="" />
                </div>
                <div className="req-det">
                  <div
                    className="det-det"
                    onClick={() => {
                      history.push(`/wayagram-profile/${member?.id}`);
                    }}
                  >
                    <div>{member?.displayName}</div>
                    <div>{`@${member?.username}`}</div>
                  </div>

                  <div
                    className="det-button"
                    style={{
                      position: 'relative',
                    }}
                  >
                    <Button
                      type="button"
                      onClick={() => {
                        setSelected(member?.id);
                        if (btnText === 'Message') handleNewChat(member);
                        else handleNewChat(member?.id);
                      }}
                      loading={btnLoading && member?.id === selected}
                      content={btnText || 'Invite'}
                    />
                  </div>
                </div>
              </div>
            )) */ ? (
            <List
              itemLayout="horizontal"
              dataSource={members}
              renderItem={(item, index) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    avatar={<Avatar src={`${item?.avatar || avatar2}`} />}
                    title={
                      <div className="links">
                        <span to={`/wayagram-profile/${item?.id}`}>
                          {item?.displayName}
                        </span>
                        <p>
                          <Link
                            to={`/wayagram-profile/${item?.id}`}
                          >{`@${item?.username}`}</Link>
                        </p>
                      </div>
                    }
                    description={
                      <Button
                        type="button"
                        onClick={() => {
                          setSelected(item?.id);
                          if (btnText === 'Message') handleNewChat(item);
                          else handleNewChat(item?.id);
                        }}
                        loading={btnLoading && item?.id === selected}
                        content={btnText || 'Invite'}
                      />
                    }
                  />
                </List.Item>
              )}
            />
          ) : (
            <div className="wl-100 my-4 text-center">No Friends</div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default Invite;
