import React, { useContext, useEffect, useState } from 'react';
//  import { Formik, Form } from 'formik';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  //  CloseCircleOutlined,
  CreditCardOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { NotificationManager } from 'react-notifications';
import swal from 'sweetalert';
import { Switch } from 'antd';
import Layout from '../../../shared/Layout';
import Loader from '../../../shared/Loader';
//  import RightSidebar from '../RightSidebar';
import '../index';
import candidate from '../../../../assets/image/right-user.svg';
//  import FormInput from '../../../shared/CustomInput/FormInput';
//  import Button from '../../../shared/Button';
import { customBaseUrl, httpGet, httpPatch } from '../../../../action/http';
import CandidateCard from './candidatesCards';
import commentIcon from '../../../../assets/image/message-icon.png';
import { PromotedIcon } from '../../../shared/Icons';
import { hideLoader, showLoader } from '../../../../utils/loader';
import ChangeStage from './stageModal';
import { retrieveMoney } from '../container';
import { WayagramContext } from '../../../../store/context/WayagramContext';

const ContestDashboard = (props) => {
  const history = useHistory();
  const { wayagramProfileId } = useContext(WayagramContext);
  const { profileId, contestId } = useParams();
  const [details, setDetails] = useState([]);
  const [states, setStates] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [showStage, setShowStage] = useState(false);
  //  const [showSharePost, setShowSharePost] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalVote, setTotalVote] = useState(0);
  const [leaders, setLeaders] = useState({
    candidate: '',
    cVote: 0,
    sch: '',
    sVote: 0,
    state: '',
  });

  const handleGetContestDetails = async () => {
    console.log({ stateData, totalVote, loading, history });
    setLoading(true);
    // const search = `all-posts/search-products?query=${value}`;
    const uri = `contests?profileId=${profileId}`;
    const res = await httpGet(`${customBaseUrl.contestUrl}/${uri}`);
    if (res?.status) {
      const contest = res?.data?.filter((e) => e?.contest?.id === contestId)[0];
      setDetails(contest?.contest);
      setLoading(false);
      setTotalVote(contest?.contest?.rankings?.totalVotes ?? 0);
      console.log('contes', contest?.contest?.Contestants);
      const lead = contest?.contest?.Contestants?.filter(
        (e) => e?.rank?.position === 1
      )[0];
      setLeaders({
        ...leaders,
        candidate: `${lead?.firstName} ${lead?.lastName}`,
        cVote: `${lead?.voteCounts}`,
        affiliation: `${lead?.affiliationName}`,
        aVote: `${lead?.rank?.votePercentage}`,
        state: lead?.state,
      });
      const uniqStates = [];
      contest?.contest?.Contestants.forEach((x) => {
        if (
          uniqStates.some((val) => {
            return val?.state === x?.state;
          })
        ) {
          uniqStates.forEach((k) => {
            if (k.state === x.state) {
              /* eslint-disable no-param-reassign */
              k.occurrence += 1;
            }
          });
        } else {
          const a = {};
          a.state = x.state;
          a.occurrence = 1;
          uniqStates.push(a);
        }
      });
      setStateData(uniqStates);
    } else {
      setLoading(false);
    }
    //  setLoading(false);
  };
  const getStates = async () => {
    const res = await httpGet(`${customBaseUrl.contestUrl}/users/states`);
    if (res?.status) {
      setStates(res?.data);
    } else {
      console.log(res);
    }
  };

  const handleRetrieve = async () => {
    showLoader();
    const res = await retrieveMoney(contestId, 'Contest', wayagramProfileId);
    if (res?.status) {
      NotificationManager.success('Request sent', res?.message, 3000);
    } else NotificationManager.error('Error', res?.message, 3000);
    hideLoader();
  };
  // const handleLike = async () => {
  //   const payload = {
  //     contestId,
  //     profileId,
  //   };
  //   const res = await httpPost(
  //     `/contests/like`,
  //     payload,
  //     customBaseUrl.contestUrl
  //   );
  //   if (res?.status) {
  //     handleGetContestDetails();
  //   } else {
  //     console.log(res);
  //   }
  // };
  // const handleRepost = async () => {
  //   const payload = {
  //     contestId,
  //     profileId,
  //   };
  //   const res = await httpPost(
  //     `/contests/repost`,
  //     payload,
  //     customBaseUrl.contestUrl
  //   );
  //   if (res?.status) {
  //     handleGetContestDetails();
  //   } else {
  //     console.log(res);
  //   }
  // };
  // const dropDownOptions = [
  //   // 'Edit',
  //   // 'Manage Contestants',
  //   // 'Manage States',
  //   // 'Manage Dashboard',
  //   // 'Delete Contest',
  //   // 'Share Contest',
  //   // 'Invite Contestants',
  // ];
  // const handleDropdownAction = (item, row) => {
  //   console.log({ item, row });
  //   if (item === 'Share Contest') setShowSharePost(true);
  // };

  // const getStateLogo = async (state) => {
  //   const log = await states?.filter((e) => e.state === state)[0];
  //   return log?.logo;
  // };

  useEffect(() => {
    console.log({ states, profileId, contestId });
    getStates();
    handleGetContestDetails();
  }, [profileId]);
  //  const { setHeaders } = useContext(HeaderContext);

  // const handleSharePost = async (item) => {
  //   const url = `${process.env.REACT_APP_WEBSITE_APP}?from=contest&contestid=${details?.id}`;
  //   setHeaders({
  //     image:
  //       'https://storage.googleapis.com/cmperstribe_storage_usha/Banner/IMG_3640.JPG',
  //     title: details?.description,
  //     url,
  //   });
  //   const shareText = details?.description
  //     ? truncateWithEllipses(details?.description, 30)
  //     : '';
  //   if (item === 'whatsapp') {
  //     window.open(
  //       // `https://wa.me/?text=${description}%0D%0A${url}`,
  //       whatsappLink(url, shareText),
  //       '_blank'
  //     );
  //   } else if (item === 'twitter') {
  //     window.open(
  //       `https://twitter.com/share/${objectToGetParams({
  //         url,
  //         text: shareText,
  //       })}`,
  //       '_blank'
  //     );
  //   } else if (item === 'facebook') {
  //     window.open(
  //       `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
  //         u: url,
  //         quote: shareText,
  //         // hashtag: '#wayagram',
  //         // imageurl:
  //         //   'https://social-waya-bucket.s3.eu-west-1.amazonaws.com/bts-1651849826823.octet-stream',
  //         // imageurl: PostImages[0].imageURL,
  //       })}`,
  //       '_blank'
  //     );
  //   } else if (item === 'mail') {
  //     window.open(
  //       `mailto:${objectToGetParams({
  //         subject: 'share post',
  //         body: shareText ? `${shareText} Check out this post ${url}` : url,
  //       })}`
  //     );
  //   } else if (item === 'link') {
  //     //  const d = `${shareText} \n${url}\n`;
  //     copyIdToClipboard(url, 'Post link copied to clipboard');
  //   }
  //   const postData = {
  //     item_id: details?.id,
  //     item_type: 'Contest',
  //     shared_to: item,
  //   };
  //   await httpPost(
  //     `all-posts/share-post?profile_id=${profileId}`,
  //     postData,
  //     customBaseUrl.wayagramUrl
  //   );
  // };
  const updateCon = async () => {
    const updateData = {
      id: details?.id,
      profileId: details?.profileId,
      //  title: values.title,
      // state: location?.state,
      // winnerPrize: values.winnerPrize,
      // description: values.description,
      // numberOfWeeks: values.numberOfWeeks,
      // maxContestants: values.maxContestants,
      // amountPerVote: values.amountPerVote,
      // //  type: values.type,
      // overallResult: values.overallResult,
      // publicity: values.publicity,
      status: details.status === 'ACTIVE' ? 'CLOSED' : 'ACTIVE',
      // startDate: postData?.startDate,
      // endDate: postData?.endDate,
      // registrationStartDate: postData?.registrationStartDate,
      // registrationEndDate: postData?.registrationEndDate,
    };
    //  if (!values.amountPerVote) delete updateData?.amountPerVote;
    showLoader();
    const res = await httpPatch(
      `/contests/update`,
      updateData,
      customBaseUrl.contestUrl
    );
    if (res.status) {
      NotificationManager.success(res?.message, 'Done!', 3000);
      hideLoader();
      // setLoading(false);
      // hideModal(false);
      handleGetContestDetails();
    } else {
      hideLoader();
      //  setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };
  return (
    <Layout
      route={props}
      currentPage="contest"
      pageName="Manage profile"
      showName
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="row wayagram-div px-5">
          <div className="mt-4 mx-auto rounded search-container shadow-lg py-5">
            {/* <div className="row">
              <div className="col-md-4">Overview</div>
              <div className="col-md-4 text-center">Live</div>
              <div className="col-md-4 text-end">Option</div>
            </div> */}
            <div className="row d-flex justify-content-between">
              <div
                className="suggest-box mx-1 my-3 row rounded text-center border"
                style={{ fontSize: 14, width: 180 }}
              >
                <div className="py-3">
                  <img src="" alt="..." />
                </div>
                <div className="text-center mt-3">
                  <p className="fw-bold">
                    {details?.Contestants?.filter((e) => e?.status === 'ACTIVE')
                      .length ?? 0}
                  </p>
                  <p>Total Contestants</p>
                </div>
              </div>
              <div
                className="suggest-box mx-1 my-3 row rounded text-center border"
                style={{ fontSize: 14, width: 180 }}
              >
                <div className="py-3">
                  <img src="" alt="..." />
                </div>
                <div className="text-center mt-3">
                  <p className="fw-bold">{totalVote}</p>
                  <p>Total Votes</p>
                </div>
              </div>
              <div
                className="suggest-box mx-1 my-3 row rounded text-center border"
                style={{ fontSize: 14, width: 180 }}
              >
                <div className="py-3">
                  <img src="" alt="..." />
                </div>
                <div className="text-center mt-3">
                  <p className="fw-bold">{leaders?.state ?? 'N/A'}</p>
                  <p>Hihgest Contestants State</p>
                </div>
              </div>
              <div
                className="suggest-box mx-1 my-3 row rounded text-center border"
                style={{ fontSize: 14, width: 180 }}
              >
                <div className="py-3">
                  <img src="" alt="..." />
                </div>
                <div className="text-center mt-3">
                  <p className="fw-bold">{leaders?.state ?? 'N/A'}</p>
                  <p>Leading State</p>
                </div>
              </div>
              <div
                className="suggest-box mx-1 my-3 row rounded text-center border"
                style={{ fontSize: 14, width: 180 }}
              >
                <div className="py-3">
                  <img src="" alt="..." />
                </div>
                <div className="text-center mt-3">
                  <p className="fw-bold">{leaders?.affiliation ?? 'N/A'}</p>
                  <p>Leading Church</p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 mx-auto rounded search-container shadow-lg py-5">
            <div>
              <img src={candidate} className="w-10 h-10 mb-3 mx-3" alt="..." />
              Top 10 Leading Candidates
              <span className="text-end px-5">
                <Link to={`/contest/contestants/${contestId}/${profileId}`}>
                  View All
                </Link>
              </span>
            </div>
            <PerfectScrollbar style={{ maxWidth: 'auto', display: 'flex' }}>
              {details?.Contestants
                ? Object.keys(details?.Contestants)
                    ?.filter(
                      (e) => details?.Contestants[e]?.status === 'ACTIVE'
                    )
                    .map((e) => (
                      <CandidateCard
                        key={e}
                        item={{
                          username: 'fff',
                          id: details?.Contestants[e]?.id,
                          displayName: `${details?.Contestants[e]?.firstName} ${details?.Contestants[e]?.lastName}`,
                          state: `${
                            details?.Contestants[e]?.state
                              .charAt(0)
                              .toUpperCase() +
                            details?.Contestants[e]?.state.slice(1)
                          } - Nigeria`,
                          avatar:
                            details?.Contestants[e]?.miscellaneous
                              ?.contestPhoto[0],
                          contestId: details?.Contestants[e]?.contestId,
                          profileId: details?.Contestants[e]?.profileId,
                          email: details?.Contestants[e]?.email,
                          contestantId: details?.Contestants[e]?.id,
                          votes: details?.Contestants[e]?.voteCounts,
                        }}
                      />
                    ))
                : ''}
            </PerfectScrollbar>
          </div>
          <div className="mt-4 mx-auto rounded search-container shadow-lg py-2 mb-5">
            <div className="row">
              <div className="col-md-3 px-5">
                <i
                  className="mdi mdi-heart-outline text_text add-cursor mx-2"
                  role="button"
                  tabIndex={0}
                  aria-hidden="true"
                />{' '}
                Total Likes {details?.likeCounts}
              </div>
              <div className="col-md-3">
                <img
                  src={commentIcon}
                  alt="comment"
                  style={{
                    width: '1em',
                    height: '1em',
                    cursor: 'pointer',
                  }}
                />
                Total Comments {details?.commentCounts}
              </div>
              <div className="col-md-3">
                <i className="mdi mdi-twitter-retweet mx-1" />
                Total Repost {details?.repostCounts}
              </div>
              <div className="col-md-3">
                <i
                  className="mdi mdi-share-variant mx-2"
                  role="button"
                  tabIndex={0}
                  aria-hidden="true"
                />
                Total Share {details?.repostCounts}
              </div>
            </div>
          </div>
          <div className="mt-4 mx-auto rounded search-container shadow-lg py-2 mb-5">
            <div className="row" style={{ fontSize: 8 }}>
              <div className="col btn border d-flex align-items-center">
                <Switch
                  defaultChecked={details?.status === 'ACTIVE'}
                  onClick={updateCon}
                />
                {details?.status === 'ACTIVE'
                  ? 'Deactivate Contest'
                  : 'Activate Contest'}
              </div>
              <div className="col btn border mx-1 d-flex align-items-center">
                <EyeOutlined />
                View Contest Voters
              </div>
              <div className="col btn border mx-1 d-flex align-items-center">
                <PromotedIcon />
                Promote Contest
              </div>
              <div
                onClick={handleRetrieve}
                className="col btn border mx-1 d-flex align-items-center"
              >
                <CreditCardOutlined />
                Retrieve Payment
              </div>
              <div
                onClick={() => setShowStage(true)}
                className="col mx-1 btn border d-flex align-items-center"
              >
                <span className="mx-1">Change Stage</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {showStage ? (
        <ChangeStage
          showModal={showStage}
          hideModal={setShowStage}
          data={details}
        />
      ) : (
        ''
      )}
    </Layout>
  );
};

export default ContestDashboard;
