import React, { useContext, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { NotificationManager } from 'react-notifications';
import swal from 'sweetalert';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ProgressBar from '../DonationModal/ProgressBar';
import EventInfo from './EventInfo';
// import EventLocation from './EventLocation';
import SearchEventLocation from './SearchEventLocation';
import EventTime from './EventTime';
import EventTicket from './EventTicket';
import PageCount from '../DonationModal/Pagecount';
import PublishEventModal from './PublishEventModal';
import FinishButton from '../DonationModal/StepButton';

import { headers, eventButtonTitle } from './data';
import '../DonationModal/index.scss';
import { customBaseUrl, httpPatch, httpPost } from '../../../../../action/http';
import { hideLoader, showLoader } from '../../../../../utils/loader';
import { WayagramContext } from '../../../../../store/context/WayagramContext';
import { userTheme } from '../../../../../store/initialState';

const CreateEventModal = (props) => {
  const theme = useRecoilValue(userTheme);
  const { showModal, hideModal, setShowPublishModal, data, mode } = props;
  const {
    wayagramProfile: profile,
    reload,
    setReload,
  } = useContext(WayagramContext);
  const [values, setValues] = useState({
    step: 0,
    totalSteps: 5,
    eventName: '',
    organizersName: '',
    eventOrganizer: '',
    details: '',
    isPrivate: true,
    isPaid: 'true',
    eventPoster: null,
    eventStart: null,
    eventEnd: null,
    location: '',
    urlLink: '',
    amount: 0,
    virtual: false,
    no_of_ticket: 0,
    selectedType: data?.virtual ? 'Virtual' : 'Physical',
    ...data,
  });
  const handleSteps = () => {
    setValues((prev) => ({
      ...prev,
      step: values.step + 1,
    }));
  };

  const stepBackward = () => {
    if (values.step > 0) {
      setValues((prev) => ({
        ...prev,
        step: values.step - 1,
      }));
    }
  };

  const handleFormInput = (input, name) => {
    setValues({ ...values, [name]: input });
  };

  const submitEvent = async () => {
    showLoader();
    if (!values.eventPoster) {
      swal('error', 'Event Poster cannot be empty');
      hideLoader();
      return;
    }
    const newForm = new FormData();
    /* eslint-disable no-unused-expressions */
    typeof values?.eventPoster !== 'string' &&
      newForm.append('eventPoster', values.eventPoster);
    newForm.append('profile_id', profile.id);
    mode === 'edit' && newForm.append('event_id', data.id);
    newForm.append('eventName', values.eventName);
    // newForm.append('email', values.eventName);
    // newForm.append('id', values.eventName);
    newForm.append('details', values.details);
    newForm.append('isPrivate', values.isPrivate || false);
    newForm.append('isPaid', values.isPaid || false);
    newForm.append('no_of_ticket', values.no_of_ticket || false);
    newForm.append('eventOrganizer', values.eventOrganizer);
    newForm.append('location', values.location);
    newForm.append('eventStart', values.eventStart);
    newForm.append('eventEnd', values.eventEnd);
    newForm.append(
      'amount',
      values.isPaid || values.isPaid === 'true' ? values.amount : 0
    );
    newForm.append('virtual', values.virtual || false);
    values.urlLink && newForm.append('eventUrl', values.urlLink);

    let res;
    if (mode === 'create') {
      res = await httpPost(
        `/all-posts/create-event`,
        newForm,
        customBaseUrl.wayagramUrl
      );
    } else {
      res = await httpPatch(
        `/all-posts/update-event`,
        newForm,
        customBaseUrl.wayagramUrl
      );
    }
    if (res?.status) {
      hideLoader();
      hideModal(false);
      setShowPublishModal(false);
      setReload(!reload);
      NotificationManager.success(res?.message, 'Done!', 3000);
    } else {
      hideLoader();
      swal('Oops!', res.message, 'error');
      setShowPublishModal(false);
    }
  };

  return (
    <Modal
      isOpen={showModal}
      id="donation-modal"
      toggle={() => hideModal(false)}
      centered
      size="lg"
    >
      <ModalHeader
        className="text-left"
        toggle={() => hideModal(false)}
        style={theme.modalStyle}
      >
        <div className="interest-header normal-header" style={theme.modalStyle}>
          <h3 className="interest-p1" style={theme.modalStyle}>
            {headers[values.step].header}
          </h3>
          <div className="description-header mt-3">
            <p className="description-text" style={theme.modalStyle}>
              {headers[values.step].description}
            </p>
          </div>
        </div>
      </ModalHeader>
      <ModalBody style={theme.modalStyle}>
        <PageSwitch
          step={values.step}
          totalSteps={values.totalSteps}
          handleSteps={handleSteps}
          stepBackward={stepBackward}
          submitEvent={submitEvent}
          handleFormInput={handleFormInput}
          values={values}
          setValues={setValues}
          theme={theme}
        />
      </ModalBody>
    </Modal>
  );
};

function PageSwitch(props) {
  const [errors, setErrors] = useState(null);
  const {
    step,
    totalSteps,
    handleSteps,
    submitEvent,
    handleFormInput,
    stepBackward,
    values,
    theme,
    setValues,
  } = props;

  const onAction = [
    handleSteps,
    handleSteps,
    handleSteps,
    handleSteps,
    submitEvent,
  ];
  const handleSubmit = () => {
    if (
      values.eventName === '' ||
      values.eventOrganizer === '' ||
      values.details === '' ||
      (step === 1 && values.location === '') ||
      (step === 1 && values.virtual && values.urlLink === '') ||
      (step === 2 && !values.eventStart) ||
      (step === 2 && !values.eventEnd) ||
      (step === 3 &&
        values.isPaid === 'true' &&
        (values.amount === '0' || !values.amount)) ||
      (step === 3 && (values.no_of_ticket === '0' || !values.no_of_ticket)) ||
      (step === 3 && !values.no_of_ticket)
    ) {
      setErrors('All input fields marked with an asterisk (*) are mandatory');
      return;
    }
    setErrors('');
    const nextAction = onAction[step];
    nextAction();
  };
  return (
    <div className="donation-container">
      {step !== 4 ? (
        <ProgressBar totalSteps={totalSteps} step={step} values={values} />
      ) : (
        ''
      )}
      <div className="info-container">
        {step === 0 && (
          <EventInfo
            handleFormInput={handleFormInput}
            values={values}
            theme={theme}
          />
        )}
        {step === 1 && (
          <SearchEventLocation
            handleFormInput={handleFormInput}
            values={values}
            theme={theme}
            setValues={setValues}
          />
        )}
        {/* {step === 2 && <EventLocation handleFormInput={handleFormInput} />} */}
        {step === 2 && (
          <EventTime
            handleFormInput={handleFormInput}
            values={values}
            theme={theme}
          />
        )}
        {step === 3 && (
          <EventTicket
            handleFormInput={handleFormInput}
            values={values}
            theme={theme}
          />
        )}
        {step === 4 && (
          <PublishEventModal
            handleFormInput={handleFormInput}
            values={values}
            theme={theme}
          />
        )}
        <p>{errors}</p>
        {step < 5 ? (
          <PageCount
            currentPage={step + 1}
            totalPage={totalSteps}
            stepBackward={stepBackward}
            color={theme?.modalStyle?.color}
          />
        ) : (
          ''
        )}
      </div>
      <FinishButton
        title={eventButtonTitle[step]}
        onClick={handleSubmit}
        className="mb-1"
      />
    </div>
  );
}

export default CreateEventModal;
