import { Formik, Form } from 'formik';
import { useRecoilValue } from 'recoil';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import swal from 'sweetalert';
import { NotificationManager } from 'react-notifications';
import { userTheme } from '../../../../store/initialState';
import { customBaseUrl, httpPost } from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';
import FormInput from '../../../shared/CustomInput/FormInput';
import AuthButton from '../../../shared/AuthButton';

const ChangeStage = (props) => {
  const theme = useRecoilValue(userTheme);
  const { showModal, hideModal, id, data, handleGetContest } = props;
  const [postData, setPostData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleUpdate = async (values) => {
    const updateData = {
      contestId: data?.id,
      profileId: data?.profileId,
      stage: values?.stage,
      startDate: postData?.startDate,
      endDate: moment(postData?.endDate).format('l'),
      voteBenchmark: values?.voteBenchmark,
    };
    showLoader();
    const res = await httpPost(
      `/contests/stages/update`,
      updateData,
      customBaseUrl.contestUrl
    );
    if (res.status) {
      NotificationManager.success(res?.message, 'Done!', 3000);
      hideLoader();
      setLoading(false);
      hideModal(false);
      handleGetContest();
    } else {
      hideLoader();
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };

  useEffect(() => {
    // getStates();
    // if (mode === 'edit') {
    //   setPostData({
    //     ...postData,
    //     startDate: new Date(data?.contest?.startDate).getTime(),
    //     endDate: new Date(data?.contest?.endDate).getTime(),
    //     registrationStartDate: new Date(
    //       data?.contest?.registrationStartDate
    //     ).getTime(),
    //     registrationEndDate: new Date(
    //       data?.contest?.registrationEndDate
    //     ).getTime(),
    //   });
    // }
    //  setFilteredOption(states);
  }, []);
  const initialValues1 = {
    voteBenchmark: 0,
    stage: 'stage-two',
  };
  return (
    <Modal
      isOpen={showModal}
      toggle={() => {
        hideModal(false);
        // setPreview([]);
      }}
      id={id}
      centered
    >
      <ModalHeader
        className="text-left"
        toggle={() => hideModal(false)}
        style={theme.modalStyle}
      >
        <span style={theme.modalStyle}>Change Stage</span>
      </ModalHeader>
      <ModalBody className="">
        <section className="flex flex-col items-center shadow-2xl p-10 bg-white">
          <Formik onSubmit={handleUpdate} initialValues={initialValues1}>
            <Form class="row g-3">
              <div className="col-12">
                <label htmlFor="stage" className="form-label">
                  Contest Stage
                </label>
                <FormInput
                  as="select"
                  type="select"
                  id="category"
                  className="form-select"
                  name="stage"
                >
                  <option value="stage-two">Stage Two</option>
                  <option value="stage-three">Stage Three</option>
                </FormInput>
              </div>
              <div className="col-12">
                <label htmlFor="voteBenchmark" className="form-label">
                  Vote Benchmark
                </label>
                <FormInput id="voteBenchmark" name="voteBenchmark" />
              </div>
              <div className="col-md-6">
                <label htmlFor="startDate" className="form-label">
                  Start Date
                </label>
                <DatePicker
                  className={`w-100 py-1 rounded border bg-${
                    theme.mode === 'dark' ? 'secondary' : 'white'
                  }`}
                  name="startDate"
                  showTimeSelect
                  selected={parseInt(postData?.startDate, 10)}
                  minDate={new Date()}
                  timeIntervals={15}
                  onChange={(date) => {
                    setPostData({
                      ...postData,
                      startDate: date.getTime(),
                      // startTime: date.getTime(),
                    });
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="endDate" className="form-label">
                  End Date
                </label>
                <DatePicker
                  className={`w-100 border py-1 rounded bg-${
                    theme.mode === 'dark' ? 'secondary' : 'white'
                  }`}
                  name="endDate"
                  showTimeSelect
                  selected={parseInt(postData?.endDate, 10)}
                  minDate={new Date()}
                  timeIntervals={15}
                  onChange={(date) => {
                    setPostData({
                      ...postData,
                      endDate: date.getTime(),
                      // endTime: date.getTime(),
                    });
                  }}
                />
              </div>
              <AuthButton
                loading={loading}
                disabled={loading || false}
                className="btn btn-primary ml-auto mb-3 mt-2"
                color="primary"
                content="Proceed"
                // onClick={}
              >
                Next
              </AuthButton>
            </Form>
          </Formik>
        </section>
      </ModalBody>
    </Modal>
  );
};

export default ChangeStage;
