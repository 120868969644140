import React, { createContext, useState, useEffect } from 'react';
import { customBaseUrl, httpGet } from '../../action/http';

// export const userId = localStorage.getItem('userId');

export const ProfileContext = createContext();

export const ProfileContextProvider = (props) => {
  const { children } = props;
  const [profile, setProfile] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [reload, setReload] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [isBvnLinked, setIsBvnLinked] = useState(true);
  const [verifiedEmailReload, setVerifiedEmailReload] = useState(false);
  const [reloadUser, setReloadUser] = useState(false);
  const [emailVerified, setEmailVerified] = useState(true);
  const [phoneVerified, setPhoneVerified] = useState(true);

  const getProfileState = async (userId) => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/profile/${Number(userId)}`
    );
    if (res?.status) {
      setProfile(res?.data);
    }
  };

  const getUserState = async (userId) => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/user/${Number(userId)}`
    );
    if (res?.status) {
      // console.log(res?.data?.phoneVerified);
      setEmailVerified(res?.data?.emailVerified);
      setPhoneVerified(res?.data?.phoneVerified);
      setUserDetails(res?.data);
    }
  };

  const getReferralCode = async (userId) => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/referral/referral-code/${Number(userId)}`
    );
    if (res?.status) {
      setReferralCode(res?.data?.referralCode);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    getUserState(userId);
  }, [reloadUser]);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    getProfileState(userId);
    getReferralCode(userId);
  }, [reload]);

  return (
    <ProfileContext.Provider
      value={{
        setProfile,
        profile,
        reload,
        setReload,
        referralCode,
        isBvnLinked,
        setIsBvnLinked,
        setVerifiedEmailReload,
        verifiedEmailReload,
        userDetails,
        setReloadUser,
        reloadUser,
        setPhoneVerified,
        phoneVerified,
        setEmailVerified,
        emailVerified,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
