import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Progress, Space } from 'antd';
import { useRecoilValue } from 'recoil';
import { MenuOutlined } from '@ant-design/icons';
import { NavbarMenu, NavbarWrapper } from './style';
import ManageBankAccountModal from '../Modals/BankAccount/manage';
import ManageCardModal from '../Modals/Card/manage';
import CreateWalletModal from '../Modals/Wallet';
import LinkCardModal from '../Modals/Card';
import BankAccountModal from '../Modals/BankAccount';
import ManageWallet from '../Modals/Wallet/ManageWallet';
import { LayoutContext } from '../../../store/context/LayoutContext';
import { ArrowBack } from '../Icons';
import WayagramNavbar from './wayagramNavbar';
import { userTheme } from '../../../store/initialState';

export default function Navbar(props) {
  const { currentPage, pageName, goBack, showName } = props;
  const [showManageBankAccount, setShowManageBankAccount] = useState(false);
  const [showManageCard, setShowManageCard] = useState(false);
  const [showManageWallet, setShowManageWallet] = useState(false);
  const [showCreateWallet, setShowCreateWallet] = useState(false);
  const [showLinkCardModal, setShowLinkCardModal] = useState(false);
  const [showAddBankAccount, setShowAddBankAccount] = useState(false);
  const {
    setToggleMobileSidebar,
    toggleMobileSidebar,
    progress,
    showProgress,
  } = useContext(LayoutContext);
  const history = useHistory();
  const theme = useRecoilValue(userTheme);
  const themeColor = theme.mode === 'light' ? 'dark' : 'white';

  return (
    <NavbarWrapper className="navbar-container" bgColor={theme.style}>
      <NavbarMenu>
        <Button
          shape="default"
          icon={<MenuOutlined />}
          onClick={() => setToggleMobileSidebar(!toggleMobileSidebar)}
        />
      </NavbarMenu>
      {goBack && (
        <Space direction="horizontal" onClick={() => history.push('/settings')}>
          <ArrowBack color={theme?.style?.color} />
          Back
        </Space>
      )}
      <h6 className={`nav-title text-${themeColor}`}>{pageName}</h6>
      <div
        style={{
          display: showProgress ? 'none' : 'none',
          flexDirection: 'row',
          width: '50%',
        }}
      >
        <div>
          <h6>Upload progress</h6>
        </div>
        <Progress percent={progress} />
      </div>
      <WayagramNavbar
        setShowManageBankAccount={setShowManageBankAccount}
        setShowCreateWallet={setShowCreateWallet}
        setShowManageCard={setShowManageCard}
        setShowManageWallet={setShowManageWallet}
        setToggleMobileSidebar={setToggleMobileSidebar}
        toggleMobileSidebar={toggleMobileSidebar}
        history={history}
        currentPage={currentPage}
        pageName={pageName}
        showName={showName}
        theme={theme}
      />
      {showManageBankAccount ? (
        <ManageBankAccountModal
          showModal={showManageBankAccount}
          hideModal={setShowManageBankAccount}
          id="account-wrapper"
          title="check"
          description="checking"
          center
          history={history}
          setShowAddBankAccount={setShowAddBankAccount}
        />
      ) : (
        ''
      )}

      {showAddBankAccount ? (
        <BankAccountModal
          showModal={showAddBankAccount}
          hideModal={setShowAddBankAccount}
          id="account-wrapper"
          history={history}
        />
      ) : (
        ''
      )}

      {showCreateWallet ? (
        <CreateWalletModal
          showModal={showCreateWallet}
          hideModal={setShowCreateWallet}
          id="account-wrapper"
        />
      ) : (
        ''
      )}

      {showManageWallet ? (
        <ManageWallet
          showModal={showManageWallet}
          hideModal={setShowManageWallet}
          id="account-wrapper"
          title="check"
          description="checking"
          center
          history={history}
          setShowCreateWallet={setShowCreateWallet}
        />
      ) : (
        ''
      )}

      {showManageCard ? (
        <ManageCardModal
          showModal={showManageCard}
          hideModal={setShowManageCard}
          id="account-wrapper"
          title="check"
          description="checking"
          center
          history={history}
          setShowLinkCardModal={setShowLinkCardModal}
        />
      ) : (
        ''
      )}

      {showLinkCardModal ? (
        <LinkCardModal
          showModal={showLinkCardModal}
          hideModal={setShowLinkCardModal}
          id="account-wrapper"
          history={history}
        />
      ) : (
        ''
      )}
    </NavbarWrapper>
  );
}

Navbar.defaultProps = {
  currentPage: 'others',
};

Navbar.propTypes = {
  currentPage: PropTypes.string,
};
